import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import { PageTitle } from "../globals/style";

const Wrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: 8em auto;
  @media screen and (max-width: 991px) {
    margin: 3em auto;
    padding: 5%;
  }
`;
const Title = styled.h2`
  font-size: 2.1em;
  opacity: 0.87;
  color: #000;
`;
const Link = styled.a`
  text-decoration: none;
  padding: 2em 1em;
  border-bottom: 3px solid #3f6564;
`;
const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Content = styled.p`
  opacity: 0.76;
  color: #000;
  font-family: "Raleway";
  font-size: 1.2em;
  line-height: 1.6;
  letter-spacing: 0.07em;
`;
const Button = styled.button`
  background: #3f6564;
  color: #efefef;
  border-radius: 25px;
  border: 0;
  padding: 0.3em 2em;
  float: right;
  cursor: pointer;
`;
const OmOssPage = () => {
  const {
    allStrapiLinks: { edges: Links },
  } = useStaticQuery(
    graphql`
      query {
        allStrapiLinks {
          edges {
            node {
              Link {
                title
                url
                description
              }
            }
          }
        }
      }
    `
  );

  return (
    <Layout
      title="Nyttige lenker"
      description="Her kan du finne flere nyttige lenker som du kan lese før eller etter at du har søkt advokathjelp hos VI§VE advokater"
      keywords={["advokat", "nyttige lenker", "nyttig", "liste", "advokathelp"]}
    >
      <Wrapper>
        <PageTitle>Aktuelle lenker</PageTitle>
        <LinkWrapper>
          {Links.map(({ node: { Link: link } }) => (
            <Link href={link.url} rel="external">
              <Title>{link.title}</Title>
              <Content>{link.description}</Content>
              <Button>Les mer</Button>
            </Link>
          ))}
        </LinkWrapper>
      </Wrapper>
    </Layout>
  );
};

export default OmOssPage;
